import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";

import MediaQuery from "../../../layout/MediaQuery";
import Tagline from "../../../texts/Tagline";
import Content from "../../../layout/Content/Content";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Button from "../../../../components/buttons/Button";

const Container = Styled.div`
  margin: ${(props) => (props.marginTop ? props.marginTop : 0)} 0 12rem 0;
  
  ${Media.lessThan("medium")`
    margin: ${(props) => (props.marginTop ? props.marginTop : 0)} 0 9rem 0;
  `}
  
  ${Media.lessThan("small")`
    margin: ${(props) => (props.marginTop ? props.marginTop : 0)} 0 7rem 0;
  `}
`;

const Box = Styled.div`
  background: ${(props) => props.theme.colors.gray.lighter};
  padding: 3rem 0 0 0;
  border-radius: 2rem;
  
  ${Media.greaterThan("medium")`
    padding: 6rem 0 0 0;
  `}
`;

const Text = Styled.div`
  width: 64rem;
  max-width: calc(100% - 4rem);
  margin: 0 auto;
  
  ${Media.greaterThan("large")`
    width: 85rem;
  `}
`;

const TaglineExtended = Styled(Tagline)`
  text-align: center;
  color: #7f7f7f;
`;

const HeadlineExtended = Styled(Headline)`
  text-align: center;
`;

const DescriptionExtended = Styled(Description)`
  text-align: center;
  margin-bottom: 5rem;
`;

const ImageExtended = Styled(Image)`
  width: 102rem;
  max-width: 100%;
  margin: 0 auto;
`;

const ExtendedButton = Styled(Button)`
  margin: 0 0 8rem 0;

  ${Media.lessThan("small")`
    margin: 0 0 3rem 0;
  `}

  ${Media.greaterThan("medium")`
    margin: 0 0 5rem 0;
  `}
`;

const NavButtonLink = Styled.a`
  text-align: center;
  display: block;
`;

type HomeBrainstormingProps = {
  tagline: string;
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  imageAlt: string;
  marginTop?: string;
  link?: string;
  tagAsTitle?: boolean;
};

const HomeBrainstorming = ({
  tagline,
  headline,
  description,
  imageA,
  imageB,
  imageAlt,
  marginTop,
  link,
  tagAsTitle,
}: HomeBrainstormingProps) => (
  <Container marginTop={marginTop}>
    <Content>
      <Box>
        <Text>
          {tagline && tagAsTitle && (
            <TaglineExtended as="h1">{tagline}</TaglineExtended>
          )}
          {tagAsTitle && (
            <HeadlineExtended as="h2">{headline}</HeadlineExtended>
          )}

          {tagline && !tagAsTitle && (
            <TaglineExtended>{tagline}</TaglineExtended>
          )}
          {!tagAsTitle && <HeadlineExtended>{headline}</HeadlineExtended>}

          <DescriptionExtended>{description}</DescriptionExtended>

          {link && (
            <NavButtonLink href={link}>
              <ExtendedButton variant="primarylight" solid>
                Read more
              </ExtendedButton>
            </NavButtonLink>
          )}
        </Text>

        <MediaQuery lessThan="medium">
          <ImageExtended
            fluid={"fluid" in imageB ? imageB.fluid : null}
            alt={imageAlt}
          />
        </MediaQuery>

        <MediaQuery greaterThan="medium">
          <ImageExtended
            fluid={"fluid" in imageA ? imageA.fluid : null}
            alt={imageAlt}
          />
        </MediaQuery>
      </Box>
    </Content>
  </Container>
);

export default HomeBrainstorming;
