import * as React from 'react';
import { Component } from 'react';

import Media from '@uizard-io/styled-media-query';
import { withTranslation } from 'react-i18next';
import Styled from 'styled-components/macro';

import Button from 'components/buttons/Button';
import Content from 'components/layout/Content/Content';
import Headline from 'components/texts/Headline';

import webAppImage from './cloud-sharing-web-app-cover.png';
import iOSImage from './ios15_cover.png';
import landoImage from './lando_cover.png';
import mobileWireframeImage from './mobile_wireframe_cover.png';
import productivityAppImage from './productivity_app_cover.png';
import webWireframeImage from './web_wireframe_cover.png';

const Container = Styled.div`
  position: relative;
  margin: 4rem 0 6rem 0;

  ${Media.greaterThan('medium')`
    margin: 8rem 0 12rem 0;
  `}
`;

const HeadlineExtended = Styled(Headline)`
  text-align: center;
  font-size: 3.5rem;

  ${Media.lessThan('medium')`
    font-size: 2.5rem;
  `}
`;

const Examples = Styled.div`
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${Media.greaterThan('medium')`
    width: 60rem;
  `}

  ${Media.greaterThan('large')`
    width: 100%;
  `}
`;

const Example = Styled.div`
  margin: 0 1.25rem 0 1.25rem;
  text-align: center;

  ${Media.greaterThan('medium')`
    margin: 0 2.5rem 0 2.5rem;
  `}
`;

const Link = Styled.a`
  &:hover, &:focus {
    color: ${(props) => props.theme.colors.black};
    cursor: pointer;
  }
`;

const Image = Styled.img`
  display: block;
  height: 20rem;
`;

const ImageContainer = Styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.11);

  &:after {
    content: 'Preview';
    font-weight: bold;
    color: ${(props) => props.theme.colors.white};
    padding-top: 25%;
    align-content: center;
    font-size: 2rem;
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    background: rgba(0,0,0,0.45);
    opacity: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  &:hover:after {
    opacity: 1;
  }
`;

const ExampleTitle = Styled(Headline)`
  font-size: 2rem;

  ${Media.lessThan('medium')`
    font-size: 1.6rem;
  `}
`;

const ExtendedButton = Styled(Button)`
  margin: 0 0 8rem 0;

  ${Media.lessThan('small')`
    margin: 0 0 3rem 0;
  `}

  ${Media.greaterThan('medium')`
    margin: 0 0 5rem 0;
  `}
`;

const NavButtonLink = Styled.a`
  text-align: center;
  display: block;
`;

class HomeExamples extends Component {
  state = {
    examples: [
      {
        image: landoImage,
        alt: 'startup landing page Lando example project image',
        title: 'A startup landing page',
        link: 'https://uizard.io/templates/website-templates/startup-landing-page/',
      },
      {
        image: webWireframeImage,
        alt: 'Website wireframe example project image',
        title: 'A website wireframe',
        link: 'https://uizard.io/templates/website-templates/website-wireframe/',
      },
      {
        image: productivityAppImage,
        alt: 'Productiviy mobile app project image',
        title: 'A productivity app',
        link: 'https://uizard.io/templates/mobile-app-templates/productivity-mobile-app/',
      },
      {
        image: mobileWireframeImage,
        alt: 'Mobile wireframe example project image',
        title: 'A mobile app wireframe',
        link: 'https://uizard.io/templates/mobile-app-templates/mobile-app-wireframe/',
      },
      {
        image: iOSImage,
        alt: 'iOS 15 mobile app project image',
        title: 'An iOS mobile app',
        link: 'https://uizard.io/templates/mobile-app-templates/ios-mobile-app/',
      },
      {
        image: webAppImage,
        alt: 'Cloud storage web app project image',
        title: 'A SaaS web app',
        link: 'https://uizard.io/templates/web-app-templates/cloud-storage-web-app/',
      },
    ],
  };

  render() {
    return (
      <Container>
        <Content>
          <HeadlineExtended>What would you like to design?</HeadlineExtended>
          <Examples>
            {this.state.examples.map((example) => (
              <Example>
                <Link href={example.link} target="">
                  <ImageContainer>
                    <Image src={example.image} alt={example.alt} />
                  </ImageContainer>
                  <ExampleTitle>{this.props.t(example.title)}</ExampleTitle>
                </Link>
              </Example>
            ))}
          </Examples>
          {/*
            <NavButtonLink href="/templates/">
                <ExtendedButton
                    variant="primarylight"
                    solid>
                  And more
                </ExtendedButton>
              </NavButtonLink>
              */}
        </Content>
      </Container>
    );
  }
}

export default withTranslation()(HomeExamples);
