import * as React from "react";
import Meta from "../../../components/meta";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { graphql } from "gatsby";
import Content from "../../../components/layout/Content/Content";
import Layout from "../../../components/layout/Layout";
import HomeFeatured from "../../../components/pages/home/HomeFeatured";
import HomeExamples from "../../../components/pages/home/HomeExamples";
import HomeWireframe from "../../../components/pages/home/HomeWireframe";
import HeroBannerNarrow from "../../../components/pages/solutions/HeroBannerNarrow";
import HomeTemplates from "../../../components/pages/home/HomeTemplates";
import HomeBrainstorming from "../../../components/pages/home/HomeBrainstorming";
import Shoutout from "../../../components/pages/signup/Shoutout";

const Container = Styled.div`
  margin: 10rem 0 10rem 0;

  ${Media.greaterThan("medium")`
    margin: 12rem 0 10rem 0;
  `}
`;

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Analyst = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard | Business Analyts | Uizard"
      description="The design tool for business analysts. No prior design or development experience required!"
      url="/solutions/business-analysts/"
    />

    <Container>
      <HeroBannerNarrow
        tagline="Easy Interface Design"
        headline="Uizard for business analysts"
        description="Assess business opportunities with quick and easy throwaway prototypes in minutes. No prior design or development experience required!"
        image={data.prototypingImageA.childImageSharp}
        imageSmall={data.prototypingImageB.childImageSharp}
      />

      <HomeTemplates
        tagline="Easy-to-use"
        headline="Design made easy"
        description="Design web apps, software interfaces, and mobile apps in minutes. If you can use Google Slides, Keynote, or PowerPoint, you can use Uizard!"
        imageA={data.templatesImageA.childImageSharp}
        imageB={data.templatesImageB.childImageSharp}
        imageAlt="Images of a team of users using pre-made templates in Uizard."
      />

      <HomeWireframe
        tagline="Wireframe"
        headline="Focus on what matters"
        description="Use Uizard to align on user experience and business expectations quickly with your clients and with your team.
        Create apps and websites with pre-made components and templates."
        imageA={data.wireframeImageA.childImageSharp}
        imageB={data.wireframeImageB.childImageSharp}
        imageAlt="Uizard supports wireframe mode to render designs in low-fidelity."
        videoName="uizard-low-fi-mode"
        link="/wireframing/"
      />

      <HomeBrainstorming
        headline="Real-time collaboration"
        tagline="Get feedback instantly"
        description="Just share a link to your project to start collaborating with your team or pitch your clients.
        All from your web browser. No software install required, just pure productivity."
        imageA={data.brainstormingImageA.childImageSharp}
        imageB={data.brainstormingImageB.childImageSharp}
        imageAlt="Image of a team collaborating in Uizard prototyping editor and writing comments."
      />

      <HomeFeatured />
      <HomeExamples />

      <ContentExtended>
        <Shoutout
          headline="Interface design in minutes"
          description="Design apps and websites with Uizard"
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </ContentExtended>
    </Container>
  </Layout>
);

export default Analyst;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    prototypingImageA: file(
      relativePath: { eq: "wireframing/prototyping-lowfi-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 722, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    prototypingImageB: file(
      relativePath: { eq: "wireframing/prototyping-lowfi-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    templatesImageA: file(relativePath: { eq: "home/templates-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 2260, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    templatesImageB: file(relativePath: { eq: "home/templates-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    brainstormingImageA: file(
      relativePath: { eq: "wireframing/brainstorming-low-fi-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    brainstormingImageB: file(
      relativePath: { eq: "wireframing/brainstorming-low-fi-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1016, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageA: file(
      relativePath: { eq: "home/conversion-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 938, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageB: file(
      relativePath: { eq: "home/conversion-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeImageA: file(relativePath: { eq: "home/wireframe-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeImageB: file(relativePath: { eq: "home/wireframe-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
