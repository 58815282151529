import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import Button from "../../../../components/buttons/Button";

import MediaQuery from "../../../layout/MediaQuery";
import Content from "../../../layout/Content/Content";
import Tagline from "../../../texts/Tagline";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import RichDescription from "../../../texts/RichDescription";

const Container = Styled.div`
  margin: 10rem 0 6rem 0;
  overflow: visible;

  ${Media.greaterThan("medium")`
     margin: 20rem 0 15rem 0;
  `}
`;

const Columns = Styled.div`
  ${Media.greaterThan("700px")`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

const Column = Styled.div`
  position: relative;
  
  ${Media.greaterThan("700px")`
    width: 45%;
  `}
`;

const ColumnIllustration = Styled(Column)`
  width: 100%;
  height: 42rem;
  display: flex;
  align-items: center;
  padding-right: 0;
      
  ${Media.greaterThan("700px")`
      height: auto;
      width: 50%;
      margin-top: 0;
  `}
  
  position: relative;
`;

const Text = Styled.div`
  width: 43rem;
  max-width: 100%;

  ${Media.greaterThan("large")`
    width: calc(43rem + 9.5rem);
    padding-left: 9.5rem;
  `}
`;

const RichDescriptionExtended = Styled(RichDescription)`
  margin-bottom: 5rem;

  ${Media.greaterThan("medium")`
    margin-bottom: 0;
  `}
`;

const ImageMobile = Styled(Image)`
  width: 34rem;
  margin: 0 0 3rem 0;
`;

const ImageWrapper = Styled.div`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  
  ${Media.greaterThan("medium")`
    top: 50%;
  `}
  
  ${Media.greaterThan("large")`
    left: 50%;
  `}
  
  ${Media.greaterThan("1400px")`
    left: 45%;
  `}
`;

const ImageDesktop = Styled(Image)`
  width: 27rem;
  max-width: calc(100% - 0.75rem);
  
  ${Media.greaterThan("1024px")`
    width: 35rem;
  `}
`;

const ExtendedButton = Styled(Button)`
  margin-bottom: 5rem;
  margin-top: 3rem;

  ${Media.greaterThan("medium")`
    margin-bottom: 0;
  `}

  ${Media.lessThan("medium")`
    margin-top: 1rem;
  `}
`;

const NavButtonLink = Styled.a`
`;

const HeadlineHero = Styled(Headline)`
  ${Media.greaterThan("medium")`
     width: 90%;
  `}
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

type HomeHeroVideoBannerProps = {
  tagline: string;
  headline: string;
  description: string;
  image: GatsbyImageProps;
  imageSmall?: GatsbyImageProps;
};

export default ({
  tagline,
  headline,
  description,
  image,
  imageSmall,
}: HomeHeroVideoBannerProps) => (
  <Container>
    <Content>
      <Columns>
        <Column>
          <Text>
            <TaglineExtended as="h1">{tagline}</TaglineExtended>
            <HeadlineHero as="h2">{headline}</HeadlineHero>
            <RichDescriptionExtended content={description} />

            <NavButtonLink
              href={`https://${constants.APP_DOMAIN}/auth/sign-up`}
            >
              <ExtendedButton solid>Sign up for free</ExtendedButton>
            </NavButtonLink>
          </Text>
        </Column>

        <ColumnIllustration>
          <ImageWrapper>
            <MediaQuery lessThan="medium">
              {imageSmall && (
                <ImageMobile
                  fluid={"fluid" in imageSmall ? imageSmall.fluid : null}
                  alt="Screenshot showing a group of people collaborating in real-time tocreate a mobile app in Uizard"
                />
              )}
              {!imageSmall && (
                <ImageMobile
                  fluid={"fluid" in image ? image.fluid : null}
                  alt="Screenshot showing a group of people collaborating in real-time tocreate a mobile app in Uizard"
                />
              )}
            </MediaQuery>

            <MediaQuery greaterThan="medium">
              <ImageDesktop
                fluid={"fluid" in image ? image.fluid : ""}
                alt="Screenshot showing a group of people collaborating in real-time tocreate a mobile app in Uizard"
              />
            </MediaQuery>
          </ImageWrapper>
        </ColumnIllustration>
      </Columns>
    </Content>
  </Container>
);
